@font-face {
  font-family: 'poppin';
  src: url('./fonts/Poppins-Bold.ttf');
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: 'poppin';
  src: url('./fonts/Poppins-SemiBold.ttf');
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: 'poppin';
  src: url('./fonts/Poppins-Medium.ttf');
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: 'poppin';
  src: url('./fonts/Poppins-Regular.ttf');
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: 'poppin';
  src: url('./fonts/Poppins-Light.ttf');
  font-weight: 100;
  font-display: fallback;
}

@font-face {
  font-family: pop_mid;
  src: url('./fonts/Poppins-Bold.ttf')
}



body {
  font-family: poppin !important;
  color: #000;

}

body::-webkit-scrollbar {
  width: 5px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #d3d3d3;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #666666;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #666666;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0) rotate(60deg);
  }

  50% {
    transform: translateY(10px) rotate(60deg);
  }

  100% {
    transform: translateY(0px) rotate(60deg);
  }
}


.fnt-w4 {
  font-weight: 400 !important;
}

.fnt-w5 {
  font-weight: 500 !important;
}

.fnt-w7 {
  font-weight: 700 !important;
}

.fnt-w8 {
  font-weight: 800 !important;
}

.fnt-wb {
  font-weight: bold !important;
}

.fnt-sm {
  font-size: small !important;
}

.fnt-xsm {
  font-size: x-small !important;
}

.fnt-smlr {
  font-size: smaller !important;
}

.fnt-med {
  font-size: medium !important;
}

.fnt-larg {
  font-size: large !important;
}

.fnt-largr {
  font-size: larger !important;
}

.txt-yellow {
  color: #ffc53e;
}


.menu-logo {
  width: 70px;
}



.mt-50 {
  margin-top: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.v-shadow{
  box-shadow: 0px -2px 10px 0px #999;
}

.no-border-rad{
  border-radius: 0 !important;
}

.h-100{
  height: 100vh;
}

.brd-btm-gray{
  border-bottom: #bfc1d0 1px solid;
}

.bg-yellow{
background: rgba(255, 197, 62, 1);
}

.bg-grd-yellow-hard {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(255, 197, 62, 1) 50%);
}

.b-shadow{
  box-shadow: 0px -2px 10px 0px #00000030;
}

.btn-dark {
  background-color: #000 !important;
}


.dvdr-r-gray{
  border-right: #0000003b 0.5px solid !important;
}
.dvdr-l-gray{
  border-left: #0000003b 0.5px solid !important;
}
.dvdr-t-gray{
  border-top: #0000003b 0.5px solid !important;
}
.dvdr-b-gray{
  border-bottom: #0000003b 0.5px solid !important;
}

.slider-container {
  overflow: hidden;
  height: 600px;
}

.slider-img {
  position: absolute;
  top: 84px;
  right: 100px;
  transform: rotate(60deg);
  animation: App-logo-float infinite 3s ease-in-out;
}


.nav-link {
  color: #000;
  font-weight: 500;
  font-size: large;
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
  color: rgb(255 255 255 / 90%);
}


.nav-btn {
  background: #000;
  color: #fff;
  border-radius: 4px;
  font-size: medium;
  padding-left: 12px;
  padding-right: 16px;
  padding-top: 4px;
  height: 33px;
  margin-top: 6px;
}

.blockquote-footer::after {
  content: "— ";
}



.bg-img-food {
  min-height: 300px;
  background: #00000030;
  background: linear-gradient(0deg, rgba(138, 130, 130, 1) 0%, rgba(224, 224, 224, 1) 100%);
  border: #00000017 1px solid;
}


.prod-card {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 300px rgb(0 0 0 / 20%);
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  overflow: hidden;
}


.footer {
  background: #000;
  color: #d7d7e0;
  padding-top: 24px;
}


.footer-title {
  color: #fff;
  margin-bottom: 10px;
  font-size: medium;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}


.footer_links {
  display: block;
  color: #828282;
  text-decoration: none;
  line-height: 2em;
}


.footer_social {
  display: inline-block;
  background: #e7e7e7;
  width: 26px;
  height: 26px;
  font-size: large;
  text-align: center;
  padding-top: 1px;
  border-radius: 3px;
  color: #000;
  margin-right: 4px;
  margin-top: 5px;
}

@keyframes shake {
  0% {
    transform: translate(0, 0)
  }

  1.78571% {
    transform: translate(5px, 0)
  }

  3.57143% {
    transform: translate(0, 0)
  }

  5.35714% {
    transform: translate(5px, 0)
  }

  7.14286% {
    transform: translate(0, 0)
  }

  8.92857% {
    transform: translate(5px, 0)
  }

  10.71429% {
    transform: translate(0, 0)
  }

  100% {
    transform: translate(0, 0)
  }
}

.shakable {
  animation: shake 1s infinite;
  -webkit-animation: shake 3s infinite;
}


.abn-card {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 25px rgb(0 0 0 / 7%);
  border: #0000004a 1px dashed;
  transition: all 0.3s ease-in-out;
  -moz-transition: -moz-all 0.3s ease-in-out;
  -o-transition: -o-all 0.3s ease-in-out;
}

.abn-card-sel{
  transform: scale(1.1);
  border: #f5ae08 1px solid;
}

.abn-card:hover{
  transform: scale(1.025);
  border: #f5ae08 1px solid;
}


.abn-checkbox {
  position: absolute;
  top: -10px;
  left: calc(50% - 12px);

}

.abn-checkbox>[type=checkbox] {
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  margin: 0px;
}








.k-stepper{
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-size: 14px;
  line-height: 1.4285714286;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: inherit;
  background: none;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.k-stepper .k-step-list{
  margin: 0;
  padding: 0;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  flex-direction: row;
  grid-row: 1;
  grid-column: 1 / -1;
}

.k-step-list .k-step{
  flex: 1 0 auto;
  text-align: center;
  max-width: calc(33.33%);
}

.k-step-list .k-step-link{
  margin: auto;
  max-width: 10em;
  -ms-flex-direction: column;
  flex-direction: column;
  outline: none;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.k-stepper .k-step-indic{
  border-color: #000;
  color: #ffffff;
  background-color: #f5ae08;
  border-radius: 50%;
  margin: 3px;
  width: 28px;
  height: 28px;
  border-width: 1px;
  border-style: solid;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: visible;
  transition-property: color, background-color, border-color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  box-sizing: content-box;
}

.k-step-link .not-done{
  background-color: #f5c964 !important;
  border-color: #7e7e7e !important;
}

.k-step-indic::after{
  border-radius: 100%;
  content: "";
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  pointer-events: none;
  display: none;
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  z-index: 2;
  display: block;
  box-shadow: inset 0 0 0 2px white;
}

.k-stepper .k-icon{
  width: 1em;
  height: 1em;
  outline: 0;
  font-size: 16px;
  font-family: "WebComponentsIcons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.k-step-list .k-label{
  max-width: 10em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}

.k-stepper-line{
  grid-column: 2/6;
  width: 100%;
  height: 2px;
  top: 17px;
  grid-row: 1 / -1;
  pointer-events: none;
  z-index: 0;
  background-color: #bfc1d0;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  border-radius: 2px;
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  line-height: 1;
  display: inline-grid;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  -webkit-touch-callout: none;
}


.csm-btn{
  border: 0px;
  position: relative;
  vertical-align: unset;
}

.border-rad-end{
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.border-rad-start{
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.csm-form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #01ffa9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(1 255 169 / 12%);
}

.brd-col-black {
  border-color: #000;
}

.brd-rad-2{
  border-radius: 2px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type=number]{
  height: 26px !important;
}






.hd-navbar {
  background-color: #ffffff;
  box-shadow: -2px 5px 5px rgb(0 0 0 / 5%);
  /* box-shadow: -2px 5px 5px rgb(0 0 0 / 21%); */
  margin-bottom: 0;
  margin-left: 120px;
  border: none;
  min-height: 50px;
  max-height: 50px;
  border-radius: 0;
  /* border-top: #3dc185 2px solid; */
  padding-top: 4px;
}
.hd-navbar-mini {
  margin-left: 0px;
}
.hd-navbar>.sidebar-toggle {
  border: none;
  color: #333;
  border-right: 1px solid #eee;
  cursor: pointer;
  background: #ffc53e ;
  padding: 4px 4px;
  min-height: 100%;
  display: inline-block;
  transition: none;
  background: #ffc53e;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  color: #000;
  margin-left: 4px;
  margin-top: 4px;
}

.hd-navbar>.sidebar-toggle:hover {
  background: #2b4a7f;
  color:#fff;
}

.mini-sidebar{
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  display: none;
}


.main-sidebar {
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 120px;
  padding-top: 0px;
  box-shadow: 1px 0 1px rgb(0 0 0 / 13%);
  background: #fff;
  background: linear-gradient( 
180deg , rgb(39 186 119 / 0%) 0%, rgb(255 255 255) 60%);
  background: #fff;
  background: #000000;
}
.sidebar {
  padding-bottom: 10px;
  height: auto;
}

.sidebar_logo , .sidebar_logo_adm{
  display: block;
  width: 100%;
  text-align: center;
  padding: 5px;
  /* background: #fff; */
  /* box-shadow: -10px 5px 10px rgb(0 0 0 / 25%);*/
}
.sidebar_logo>img{
  width: 64px;
}
.sidebar_logo_adm>img{
  width: 80px;
}

.sidebar_logo_mini{
  padding: 0px;
  padding-top: 26px;
  padding-bottom: 26px;
}
.sidebar_logo_mini>img{
  width: 42px;
  opacity: 1;
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 70%;
  overflow-y: auto;
  margin-top: 26px;
  position: absolute;
  width: 100%;
}

.sidebar-menu_adm {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: calc(70vh) !important;
  line-height: 1.22 !important;
  padding-bottom: 32px;
}
.sidebar-menu>li.header {
  background: #1a2226;
  overflow: hidden;
  text-overflow: clip;
  padding: 10px 25px 10px 15px;
  font-size: 12px;
  color: #fff;
  background: transparent;
  font-weight: bold;
}
.sidebar-menu>li {
  position: relative;
    margin: 0;
    padding: 0;
    transition: none;
    padding: 3px 12px;
}
.sidebar-menu>li:hover{
  background: #000;
}

.sidebar-menu>li:hover a{
  background: #ffc53e !important;
    color: #000 !important;
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 10px 0px;
}

.sidebar-menu .active{
  background: #ffc53e !important;
  color: #000 !important;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 10px 0px;
}
.sidebar-menu>li>a {
  padding: 8px;
  display: block;
  background: transparent;
  border-bottom: 1px #ffffff17 solid;
  border-left: 3px solid transparent;
  color: #fff;
  text-decoration: none;
  transition: none;
  text-align: center;
  border-radius: 4px;
}

.sidebar-menu>li>a>.icon {
  font-size: larger;
}
.sidebar-menu>li>a>.title {
  display: block;
    font-size: x-small;
    font-weight: 500;
}

.cont_log_out{
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  /* box-shadow: -10px -5px 10px rgba(0, 0, 0, 0.16); */
}
.btn_log_out {
  border: none;
  color: #f44336;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 66%;
  font-weight: 500;
  background: #000;
  margin: 8px;
  border-radius: 4px;
  /* box-shadow: -10px -5px 10px rgb(0 0 0 / 16%); */
}


.rw-cont{
  margin: 0px;
  margin-left: 120px;
  margin-top: 50px;
  position: absolute;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  width: -moz-available;
  height: -moz-available;
  width: fill-available;
  height: fill-available;
  
}

.user-panel {
  position: relative;
  width: 100%;
  padding: 10px;
  overflow: hidden;
  color: #fff;
}
.user-apnel-cont{
  background: #01010117;
  border-radius: 6px;
  border: #ffffff4a 1px solid;
  padding-top: 4px;
}
.user-panel-usr-icn{
  text-align: center;
  font-size: x-large;
  padding: 0px;
}
.user-panel-usr-data{
  line-height: 1rem;
  padding: 6px;
  background: #fff;
  color: #000;
  text-align: center;
  margin-top: 4px;
  border-radius: 6px;
  box-shadow: 0px -3px 5px rgb(0 0 0 / 17%);
  margin-bottom: -1px;
}
.user-panel-usr-data>p{
  font-size: small;
  margin: 2px;
  color: #535353;
}
.user-panel-usr-data>b{
  font-size: small;
}

.user_img{
  width: 70px;
  height: 70px;
  border-radius: 100%;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 23%);
  border: #fff 1px solid;
  padding: 2px;
  object-fit: cover;
}

.user_img::after{
  border-radius: 100%;
  content: "";
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  pointer-events: none;
  display: none;
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  z-index: 2;
  display: block;
  box-shadow: inset 0 0 0 2px white;
}

.rw-cont-mini{
  margin-left: 0px;
}

.no-arrow::after {
  display:none !important;
}

.scale15{
  transform: scale(1.5);
}

.btn_round_notif{
  background-color: #ffc53e !important;
  border-radius: 100% !important;
  width: 30px;
  height: 30px;
  color: #000 !important;
  font-size: medium !important;
  margin-top: 5px;
}

.btn_round {
  font-size: large;
  color: #000;
  border: none;
  border-radius: 100%;
  box-shadow: 0 8px 6px -6px #00000057;
  width: 40px;
  height: 40px;
  background: #ffc53e !important;
  text-align: center;
  padding: 0px;
}

.btn_mini {
  width: 30px !important;
  height: 30px !important;
}

.btn_round:hover{
  background: #6279f9 ;
}
.btn_eff_purple {
  background-color: #5c4cdb !important;
  color: #fff !important;
}
.btn_eff_purple:hover {
  background-color: #335eea !important;
}


.home-banner, .home-box {
  border: 1px solid #e4e6eb;
  border-radius: 8px;
  box-shadow: -2px 5px 5px rgb(0 0 0 / 5%);
}

.page-title{
  z-index: 99;
  position: relative;
  background: #ffffff52;
}

.banner-home-img{
  position: absolute;
    right: -32px;
    top: -35px;
    width: 208px;
}

.banner-container{
  overflow: hidden;
    min-height: 120px;
}


.icon-item-round{
  font-size: 16px;
  background: #ffc53e;
  width: 32px;
  display: inline-block;
  height: 32px;
  text-align: center;
  border-radius: 100%;
  color: #000;
  padding-top: 3px;
  position: relative;
}



.inpt_search{
  display: inline-flex !important;
  width: 94% !important;
  border-radius: 16px !important;
  box-shadow: 0 8px 6px -6px #00000036;
  background-color: #edf0f3 !important;
  font-size: small !important;
}
.inpt_search:hover{
  border: #ffc53e 1px solid !important;
  background-color: #fff !important;
  padding-left: 32px !important;
}

.btn_search{
  background-color: #ffc53e !important;
  color: #000 !important;
  border-radius: 50% !important;
  padding: 2px 8px !important;
  margin-left: -33px;
  margin-top: 0px;
  width: 34px;
  height: 34px;
}

.btn_rnd{
  background: #1463c5;
  color: #fff;
  border-radius: 19px;
  padding: 6px 16px;
  margin-top: -6px;
}
.btn_search:hover , .btn_rnd:hover {
  color: #ffc53e !important;
  background-color: #000 !important;
}


.img-profile-cont{
  display: inline-block;
  border-radius: 4px;
}
.img-profile{
  width: 100%;
  opacity: 1;
    border-radius: 8px;
    border: #00000052 1px dashed;
    padding: 2px;
}





.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: isolate;
  color: #000;
  border-color: #00000014;
}


.nav-tabs .nav-link.active {
    color: #000000;
    background-color: #ffc53e;
    border-color: #00000014;
}